<style scoped lang="less">
.t_guide_student {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
  }
  .resourceForm {
    padding: 0 20px;
  }
  .footer_drawer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .detailItem_title,
  .detailItem_content {
    padding: 0 20px 20px;
  }
  .detailItem_title {
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
<template>
  <div class="t_guide_student">
    <div class="stu-module-header">
      <div class="stu-module-title">指导学生</div>
    </div>
    <div class="content">
      <el-form :model="form" size="medium" inline>
        <el-form-item label="姓名/学号">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="毕业批次">
          <el-select v-model="form.batch" :clearable="true">
            <el-option
              v-for="(item, index) in batchOptions"
              :key="index"
              :label="item.batchName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="query" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :height="height"
        @selection-change="selectionChange"
        stripe
      >
        <el-table-column
          type="selection"
          width="65"
          align="center"
        ></el-table-column>
        <el-table-column label="序号" width="65" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in column"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :min-width="item.width"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="任务书" prop="" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="task(scope.row, scope.$index)">
              查看任务
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="评语" prop="" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="remark(scope.row, scope.$index)">
              查看任务
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="成绩" prop="score" align="center"></el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <TaskDrawer ref="t_task_drawer" />
    <RemarkDrawer ref="t_remark_drawer" />
  </div>
</template>

<script>
import TaskDrawer from "./module/TaskDrawer.vue";
import RemarkDrawer from "./module/RemarkDrawer.vue";
export default {
  name: "t_guide_student",
  components: { TaskDrawer, RemarkDrawer },
  data() {
    return {
      height: window.innerHeight - 330,
      form: {
        name: null,
        batch: null,
      },
      batchOptions: [],
      column: [
        { label: "姓名", prop: "name" },
        { label: "学号", prop: "studentNo" },
        { label: "专业", prop: "profName"},
        { label: "联系电话", prop: "contactPhone"},
        { label: "类型", prop: "graduateTypes"},
        { label: "是否免试", prop: "applyFrees"},
        { label: "开题报告", prop: "openReportStatuss" },
      ],
      selectionData: [],
      tableData: [
        {
          coverUrl:
            "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
          title: "3123",
          content: "adaskdnlksdf",
        },
      ],
      total: 0,
      pageSize: 20,
      current: 1,
    };
  },
  mounted() {
    this.init();
    this.selectionInit();
  },
  methods: {
    init() {
      this.openLoadingView();
      this.$axios_supermallData({
        url: "/hnjxjy-core/eduGraduateDesignStu/queryGuidanceStuInfo",
        method: 'get',
        params: {
          page: this.current,
          pageSize: this.pageSize,
        }
      }).then((resp) => {
        if (resp.data.code == 2000) {
          this.total = resp.data.data.total;
          this.tableData = resp.data.data.list;
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      })
    },
    selectionInit(){
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/eduGraduateDesignBatch/queryEduGraduateDesignBatch").then((resp)=>{
        if (resp.data.code == 2000) {
          this.batchOptions = resp.data.data;
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      })
    },
    query() {
      this.openLoadingView();
      this.$axios_supermallData({
        url: "/hnjxjy-core/eduGraduateDesignStu/queryGuidanceStuInfo",
        method: 'get',
        params: {
          page: this.current,
          pageSize: this.pageSize,
          batchId: this.form.batch,
          studentNameOrNum: this.form.name
        }
      }).then((resp) => {
        if (resp.data.code == 2000) {
          this.total = resp.data.data.total;
          this.tableData = resp.data.data.list;
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      })
    },
    task(row, index) {
      this.$refs["t_task_drawer"].drawer = true;
    },
    remark(row, index) {
      this.$refs["t_remark_drawer"].drawer = true;
    },
    selectionChange(val) {
      this.selectionData = val;
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
  },
};
</script>
