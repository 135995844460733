<style lang="less" scoped>
.task_drawer {
  .el-form {
    padding: 0 20px;
  }
}
</style>
<template>
  <el-drawer
    :visible.sync="drawer"
    size="50%"
    title="任务书"
    class="task_drawer"
  >
    <el-form :model="form" size="medium" ref="task_drawer_form" :rules="rules">
      <el-form-item label="发给学员：">
        <span>{{ form.name }}</span>
      </el-form-item>
      <el-form-item label="设计（论文）题目：">
        <span>{{ form.title }}</span>
      </el-form-item>
      <el-form-item label="学生完成设计（论文）期限：" prop="date">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          style="max-width: 350px"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="设计（论文）课题要求（200字以内）" prop="design_ask">
        <el-input
          v-model="form.design_ask"
          type="textarea"
          :maxlength="200"
          show-word-limit
          placeholder="请输入内容"
          :autosize="{ minRows: 4, maxRows: 6 }"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="实验（上机、调研）部分要求内容（150字以内）"
        prop="content"
      >
        <el-input
          v-model="form.content"
          type="textarea"
          :maxlength="200"
          placeholder="请输入内容"
          show-word-limit
          :autosize="{ minRows: 4, maxRows: 6 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="文献查阅要求（150字以内）" prop="read_ask">
        <el-input
          v-model="form.read_ask"
          type="textarea"
          :maxlength="200"
          show-word-limit
          placeholder="请输入内容"
          :autosize="{ minRows: 4, maxRows: 6 }"
        ></el-input>
      </el-form-item>
      <el-button @click="save" type="primary">保存任务书</el-button>
    </el-form>
  </el-drawer>
</template>

<script>
export default {
  name: "task_drawer",
  data() {
    return {
      drawer: false,
      form: {
        name: "米斯特温",
        title: "前进前进前进前进",
        date: ["2021-03-01", "2021-03-01"],
        design_ask: null,
        content: null,
        read_ask: null,
      },
      rules: {
        date: [{ required: true, message: "请选择期限", trigger: "blur" }],
        design_ask: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        read_ask: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
    };
  },
  methods: {
    save() {
      this.$refs["task_drawer_form"].validate((valid) => {
        if (!valid) return false;
      });
    },
  },
};
</script>