<style lang="less" scoped>
.remark_drawer {
  .el-form {
    padding: 0 20px;
  }
}
</style>
<template>
  <el-drawer
    :visible.sync="drawer"
    size="50%"
    title="任务书"
    class="remark_drawer"
  >
    <el-form
      :model="form"
      size="medium"
      ref="remark_drawer_form"
      :rules="rules"
    >
      <el-form-item label="学员：">
        <span>{{ form.name }}</span>
      </el-form-item>
      <el-form-item label="评语：" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          :maxlength="200"
          show-word-limit
          placeholder="请输入内容"
          :autosize="{ minRows: 4, maxRows: 6 }"
        ></el-input>
      </el-form-item>
      <el-button @click="save" type="primary">保存任务书</el-button>
    </el-form>
  </el-drawer>
</template>

<script>
export default {
  name: "remark_drawer",
  data() {
    return {
      drawer: false,
      form: {
        name: "米斯特温",
        remark: null,
      },
      rules: {
        remark: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
    };
  },
  methods: {
    save() {
      this.$refs["remark_drawer_form"].validate((valid) => {
        if (!valid) return false;
      });
    },
  },
};
</script>